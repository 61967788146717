/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { tactileoTypes } from 'utils/activityItem.util';
import {
  LtiQuestionIcon,
  ScormQuestionIcon,
  H5PQuestionIcon,
  GroupQuestionIcon,
  MapQuestionIcon,
  SortingQuestionBoldIcon,
  HoleQuestionIcon,
  AudioQuestionIcon,
  MultipleChoicesQuestionIcon,
  ExplodedTextQuestionIcon,
  AssociateQuestionIcon,
  FreeTextQuestionIcon,
  CraftImageQuestionIcon,
  PollIcon,
  HotSpotQuestionIcon,
  PlaceholderIcon,
  VideoCameraIcon,
  AppWindowIcon,
  DocumentIcon,
  MonitorPlayIcon,
} from '@maskott/tactileo.designsystem';
import { FONT_TYPE_SIZE } from 'constants';

export const SCORE_NOT_ACQUIRED = 20;
export const SCORE_ACQUIRING = 50;
export const SCORE_ACQUIRED = 80;
export const SCORE_EXCEEDED = 100;
export const MAX_SHORT_CARACTER_MESSAGE = 50;

export const pascalToCamel = (o) => {
  let newO = {};
  let value;
  // check if the input is an array
  if (o instanceof Array) {
    // if it is an array, map through each element
    // and convert the object properties recursively
    return o.map((value) => {
      if (typeof value === 'object') {
        value = pascalToCamel(value);
      }
      return value;
    });
  }

  // loop through each property of the object
  for (let origKey in o) {
    // check if the property belongs to the object and not its prototype
    if (o.hasOwnProperty(origKey)) {
      // convert the key from PascalCase to camelCase
      let newKey = (
        origKey?.charAt(0).toLowerCase() + origKey.slice(1) || origKey
      ).toString();
      // get the value of the property
      value = o[origKey];
      // check if the value is an array or an object
      // if it is, convert the properties recursively
      if (
        value instanceof Array ||
        (value !== null && value?.constructor === Object)
      ) {
        value = pascalToCamel(value);
      }
      // store the new key-value pair in the new object
      newO[newKey] = value;
    }
  }
  // return the converted object
  return newO;
};

export const shuffleArray = (array) => {
  // Iterate through the array starting from the last element
  // and going to the first element
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i
    const j = Math.floor(Math.random() * (i + 1));
    // Swap the element at index i with the element at the random index j
    [array[i], array[j]] = [array[j], array[i]];
  }
  // return the shuffled array
  return array;
};

export const roundToTwo = (num) => {
  // Use the Math.round method to round the number
  // Use scientific notation to limit the number of decimal places to 2
  return +(Math.round(num + 'e+2') + 'e-2');
};

export const roundToTwenty = (raw, max) => {
  // Multiply the raw value by 20
  // Divide the result by the max value
  // Round the final result to two decimal places
  return roundToTwo((raw * max) / max);
};

export const handleTextIdMap = (data) => {
  // Initialize an empty object as the accumulator
  // Iterate through the data array
  // For each item, add the item's id as key and text as value to the accumulator object
  // Return the accumulator object
  return data.reduce((acc, item) => {
    acc[item.id] = item.text;
    return acc;
  }, {});
};

export const getExTypeIcon = (type) => {
  let exIcon;
  switch (type) {
    case tactileoTypes.LTI_QUESTION:
      exIcon = LtiQuestionIcon;
      break;
    case tactileoTypes.SCORM_QUESTION:
      exIcon = ScormQuestionIcon;
      break;
    case tactileoTypes.H5P_QUESTION:
      exIcon = H5PQuestionIcon;
      break;
    case tactileoTypes.GROUP_QUESTION:
      exIcon = GroupQuestionIcon;
      break;
    case tactileoTypes.MAP_QUESTION:
      exIcon = MapQuestionIcon;
      break;
    case tactileoTypes.SORTING_QUESTION:
      exIcon = SortingQuestionBoldIcon;
      break;
    case tactileoTypes.HOLE_QUESTION:
      exIcon = HoleQuestionIcon;
      break;
    case tactileoTypes.AUDIO_QUESTION:
      exIcon = AudioQuestionIcon;
      break;
    case tactileoTypes.MULTIPLE_CHOICES_QUESTION:
      exIcon = MultipleChoicesQuestionIcon;
      break;
    case tactileoTypes.EXPLODED_TEXT_QUESTION:
      exIcon = ExplodedTextQuestionIcon;
      break;
    case tactileoTypes.ASSOCIATE_QUESTION:
      exIcon = AssociateQuestionIcon;
      break;
    case tactileoTypes.FREE_TEXT_QUESTION:
      exIcon = FreeTextQuestionIcon;
      break;
    case tactileoTypes.CRAFT_IMAGE_QUESTION:
      exIcon = CraftImageQuestionIcon;
      break;
    case tactileoTypes.POLL_QUESTION:
      exIcon = PollIcon;
      break;
    case tactileoTypes.HOTSPOT_QUESTION:
      exIcon = HotSpotQuestionIcon;
      break;
    case tactileoTypes.VIDEO_PAGE_CONTENT:
      exIcon = VideoCameraIcon;
      break;
    case tactileoTypes.INTERNET_PAGE_CONTENT:
      exIcon = AppWindowIcon;
      break;
    case tactileoTypes.PDF_PAGE_CONTENT:
      exIcon = DocumentIcon;
      break;
    case tactileoTypes.RICH_TEXT_CONTENT:
      exIcon = MonitorPlayIcon;
      break;
    default:
      exIcon = PlaceholderIcon;
  }
  return exIcon ?? PlaceholderIcon;
};

export const isAcquire = {
  NOTACQUIRED: 'notAcquired',
  ACQUIRING: 'acquiring',
  ACQUIRED: 'acquired',
  EXCEEDED: 'exceeded',
};

export const skillsAcquisition = (raw, max) => {
  // Calculate the percent of the raw score
  const percent = (raw * SCORE_EXCEEDED) / max;

  // Return an empty string if percent is 0
  if (!percent) return '';

  // Check if the percent is less than or equal to the not acquired score
  if (percent <= SCORE_NOT_ACQUIRED) {
    return isAcquire.NOTACQUIRED;
  }

  // Check if the percent is greater than the not acquired score but less than or equal to the acquiring score
  if (percent > SCORE_NOT_ACQUIRED && percent <= SCORE_ACQUIRING) {
    return isAcquire.ACQUIRING;
  }

  // Check if the percent is greater than the acquiring score but less than or equal to the acquired score
  if (percent > SCORE_ACQUIRING && percent <= SCORE_ACQUIRED) {
    return isAcquire.ACQUIRED;
  }

  // Check if the percent is greater than the acquired score but less than or equal to the exceeded score
  if (percent > SCORE_ACQUIRED && percent <= SCORE_EXCEEDED) {
    return isAcquire.EXCEEDED;
  }

  // If the percent is not in any of the above range, return not acquired
  return isAcquire.NOTACQUIRED;
};

export const removeAccentDiacritics = (text) => {
  // Normalize the text in NFD Unicode normalization form
  // Replace any character in the range of accent diacritics with an empty string
  return text?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
};

export const normalizeText = (text, isAccentSensitive) => {
  // If text is undefined or null, return an empty string
  if (!text) return '';
  // If accent sensitive is true, return the original text
  // Otherwise, call the removeAccentDiacritics function to remove accent diacritics from the text
  return isAccentSensitive ? text : removeAccentDiacritics(text);
};

export const Capitalize = (str) => {
  // If str is undefined, "", 0, NaN or null, return ""
  if (!str) return '';
  // Capitalize the first letter of the string
  // Concatenate the capitalized first letter with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// this function recursively searching for the first element without child nodes
export const getFirstLeafElement = (element) => {
  // If the element has no childNodes or it is already a leaf element return the element
  if (!element?.childNodes || element?.childNodes?.length === 0) return element;
  //otherwise call the function recursively on the first childNode
  return getFirstLeafElement(element?.childNodes[0]);
};

export const autoAdjustInputHeight = ({ target }) => {
  // If target is undefined or null, return without doing anything
  if (!target) return;
  // Set the target's height to 5px
  target.style.height = '5px';
  // Set the target's height to its scrollHeight
  target.style.height = target.scrollHeight + 'px';
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 * @param {String} paddings the paddings from left and right borders of the text.
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth({
  text,
  font,
  paddings = 0,
  type,
  state,
  isIcon,
}) {
  // constant value for ANSWERED_PADDING and ICON_PADDING
  const ANSWERED_PADDING = 40;
  const ICON_PADDING = 50;
  const NUMBER_PADDING = 70;
  let validatedPadding = 0;

  // if state is true add ANSWERED_PADDING to validatedPadding
  if (state) {
    validatedPadding = ANSWERED_PADDING;
  }
  // if state is true and isIcon is also true add ICON_PADDING to validatedPadding
  if (state && isIcon) {
    validatedPadding = ANSWERED_PADDING + ICON_PADDING;
  }
  if (type === 'number') {
    validatedPadding = NUMBER_PADDING;
  }
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);

  //return the width of the text plus paddings and validatedPadding
  return metrics.width + paddings + validatedPadding;
}

export const checkIsLongAvatarMessage = (message) => {
  if (message.length >= MAX_SHORT_CARACTER_MESSAGE) {
    return true;
  } else {
    return false;
  }
};

export const getSessionData = (name) => {
  // parse the data from session storage with the given key
  return JSON.parse(sessionStorage.getItem(name));
};

export const setSessionData = (name, data) => {
  // store the data in session storage with the given key
  sessionStorage.setItem(name, JSON.stringify(data));
};

export const formatToKebabCase = (str) => {
  if (!str) return;
  const strFormat = str
    .replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)
    .replace(/^ms-/, '-ms-');
  return strFormat.replace(/\s+/g, '-');
};

export const getFontSizeToUse = (type, size, weight, themeSize) => {
  const FONT_SIZE_INDEX = {
    xxsmall: 0,
    xsmall: 1,
    small: 2,
    medium: 3,
    large: 4,
  };

  let delta = 0;
  switch (FONT_SIZE_INDEX[themeSize]) {
    case 2:
      delta = -1;
      break;
    case 1:
      delta = -2;
      break;
  }

  if (FONT_SIZE_INDEX[size.toLowerCase()] == FONT_SIZE_INDEX[themeSize])
    delta = 0;

  const newIndexSize = FONT_SIZE_INDEX[size.toLowerCase()] + delta;

  const newSize = Object.keys(FONT_SIZE_INDEX).find(
    (key) => FONT_SIZE_INDEX[key] === newIndexSize,
  );

  const arrayFontSize = Object.keys(FONT_TYPE_SIZE[type.toUpperCase()]);

  const validNewSize = ensureProp(
    newSize?.toUpperCase(),
    arrayFontSize,
  ).toUpperCase();

  const newFont = weight
    ? FONT_TYPE_SIZE[type.toUpperCase()][validNewSize][weight.toUpperCase()]
    : FONT_TYPE_SIZE[type.toUpperCase()][validNewSize];

  return newFont;
};

export const ensureProp = (value, array) => {
  const sizeMap = {
    large: 'medium',
    small: 'medium',
    xsmall: 'small',
    xxsmall: 'xsmall',
  };

  if (array.includes(value)) {
    return value;
  }

  if (sizeMap[value]) {
    const nextSize = sizeMap[value];
    return ensureProp(nextSize, array);
  }

  return 'medium';
};
