/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import { HotspotEvaluationModel } from 'core/models/elements/evaluations/hotspotEvaluation.model';
import { HotspotQuestionModel } from 'core/models/elements/questions/hotspotQuestion.model';
import { HotspotQuestionAnswerModel } from 'core/models/elements/answers/hotspotQuestionAnswer.model';
import { HotspotQuestionAnswerCommand } from 'core/commands/elements/answers/hotspotQuestionAnswer.command';

export class HotspotQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, hotspotQuestionAnswer) {
    if (!(hotspotQuestionAnswer instanceof HotspotQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "HotspotQuestionAnswerModel"',
      );
    }
    const hotspotQuestionAnswerCommand = new HotspotQuestionAnswerCommand(
      hotspotQuestionAnswer,
    );

    return await super.answer(
      activitySessionId,
      activityItemId,
      hotspotQuestionAnswerCommand,
    );
  }

  evaluate(hotspotQuestion, hotspotQuestionAnswer) {
    if (!(hotspotQuestion instanceof HotspotQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "HotspotQuestionModel"',
      );
    }
    if (!(hotspotQuestionAnswer instanceof HotspotQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "HotspotQuestionAnswerModel"',
      );
    }

    const evaluation = new HotspotEvaluationModel(hotspotQuestion);

    let expectedAnswers = hotspotQuestion.areas;

    if (hotspotQuestionAnswer.x < 0 || hotspotQuestionAnswer.y < 0) {
      evaluation.isAnswerComplete = false;
      evaluation.isCorrect = false;
    } else {
      evaluation.isAnswerComplete = true;
      evaluation.x = hotspotQuestionAnswer.x;
      evaluation.y = hotspotQuestionAnswer.y;
      evaluation.answerAria = null;
      const correct = expectedAnswers.some((x) => {
        if (
          x.matchCoordinates(hotspotQuestionAnswer.x, hotspotQuestionAnswer.y)
        ) {
          evaluation.answerAria = x?.id;
          return true;
        }
      });
      if (correct) {
        evaluation.isCorrect = true;
        evaluation.rawScore = hotspotQuestion.gradingScale;
        evaluation.ScaledScore = 1;
      } else {
        evaluation.isCorrect = false;
      }
    }

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, coordinates = {}) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    const coordinatesToEvaluate = JSON.parse(JSON.stringify(coordinates));

    const answer = new HotspotQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      x: coordinatesToEvaluate?.x,
      y: coordinatesToEvaluate?.y,
    });
    const evaluation = this.evaluate(item, answer);
    return {
      answer,
      evaluation,
    };
  }

  generateTagsArray(question, isAnswered) {
    if (!(question instanceof HotspotQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "HotspotQuestionModel"',
      );
    }

    if (!isAnswered) {
      return [];
    }

    return (question?.areas || []).map((area) => {
      const leftOrigin = area.x + area.width / 2;
      const topOrigin = area.y + area.height / 2;
      return {
        id: area.id,
        leftOrigin,
        topOrigin,
        left: null,
        right: null,
      };
    });
  }
}
