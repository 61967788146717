/* eslint-disable max-classes-per-file */
import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines an hotspot answer corresponding to an hole question.
 */
export class HotspotQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of an hotspot answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.x = bo.x;
    this.y = bo.y;
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new HotspotQuestionAnswerModel(bo);
  }
}
