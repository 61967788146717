import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class HotspotQuestionAnswerCommand extends AnswerCommand {
  constructor(hotspotQuestion) {
    super(tactileoAnswersDTOTypes.HOTSPOT_QUESTION, hotspotQuestion);
    this.x = hotspotQuestion.x;
    this.y = hotspotQuestion.y;
  }
}
