/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a group question
 */
export class GroupEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of group evaluation.
   * @param {groupQuestion} groupQuestion is the element to be evaluated.
   */
  constructor(groupQuestion) {
    super(groupQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a group question item
 */
export class GroupEvaluationResultModel {
  /**
   * @constructor Creates an instance of group evaluation result.
   */
  constructor(bo) {
    this.id = bo.id;
    this.text = '';
    this.itemId = null;
    this.isNoAnswer = false;
    this.isCorrect = false;
  }
}
