/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  HoleEvaluationModel,
  HoleEvaluationResultModel,
} from 'core/models/elements/evaluations/holeEvaluation.model';
import { HoleQuestionModel } from 'core/models/elements/questions/holeQuestion.model';
import { HoleQuestionAnswerModel } from 'core/models/elements/answers/holeQuestionAnswer.model';
import { HoleQuestionAnswerCommand } from 'core/commands/elements/answers/holeQuestionAnswer.command';
import { shuffleArray } from 'utils/common.util';
import { activityItemStates } from 'utils/activityItem.util';
import { LOCALE_COMPARE_SENSITIVITY, DEFAULT_LOCALE } from 'constants/index';
const { VARIANT, BASE, CASE, ACCENT } = LOCALE_COMPARE_SENSITIVITY;

export const ANSWER_KEY = 'answer-';
export const NUMERIC_RANGE_ANSWER_TYPE = 'NumericeRange';
export const NUMERIC_EVALUATION_TYPE = 1;

export class HoleQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, holeQuestionAnswer) {
    if (!(holeQuestionAnswer instanceof HoleQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "HoleQuestionAnswerModel"',
      );
    }
    const holeQuestionAnswerCommand = new HoleQuestionAnswerCommand(
      holeQuestionAnswer,
    );

    return await super.answer(
      activitySessionId,
      activityItemId,
      holeQuestionAnswerCommand,
    );
  }

  evaluate(holeQuestion, holeQuestionAnswer, culture) {
    if (!(holeQuestion instanceof HoleQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "HoleQuestionModel"',
      );
    }
    if (!(holeQuestionAnswer instanceof HoleQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "HoleQuestionAnswerModel"',
      );
    }

    const evaluation = new HoleEvaluationModel(holeQuestion);
    holeQuestion.answers
      .filter(({ isIntrusive }) => !isIntrusive)
      .forEach((currentAnswer) => {
        const answer = holeQuestionAnswer.answers?.find(
          (item) => item?.id === currentAnswer?.id,
        );
        const result = new HoleEvaluationResultModel();
        result.itemId = currentAnswer?.id;
        result.text = answer?.text;
        let isAnswerCorrect = false;
        if (
          answer?.evaluationType === NUMERIC_RANGE_ANSWER_TYPE ||
          answer?.evaluationType === NUMERIC_EVALUATION_TYPE
        ) {
          isAnswerCorrect =
            answer.text >= answer.minExpectedValue &&
            answer.text <= answer.maxExpectedValue;
        } else {
          let compareSensitivity = VARIANT;
          if (!answer?.isAccentSensitive && !answer?.isCaseSensitive) {
            compareSensitivity = BASE;
          } else if (!answer?.isAccentSensitive && answer?.isCaseSensitive) {
            compareSensitivity = CASE;
          } else if (answer?.isAccentSensitive && !answer?.isCaseSensitive) {
            compareSensitivity = ACCENT;
          }

          const checkText =
            currentAnswer?.text.localeCompare(
              answer?.text,
              culture || DEFAULT_LOCALE,
              {
                sensitivity: compareSensitivity,
              },
            ) === 0;

          const checkAlternativetext = currentAnswer.alternativeAnswers.some(
            (a) =>
              a.localeCompare(answer?.text, culture || DEFAULT_LOCALE, {
                sensitivity: compareSensitivity,
              }) === 0,
          );

          isAnswerCorrect = checkText || checkAlternativetext;
        }

        result.isCorrect = isAnswerCorrect;
        result.isNoAnswer = answer?.text === ("" || undefined);
        evaluation.results.push(result);
      });
    evaluation.isCorrect = evaluation.results.every((x) => x.isCorrect);
    evaluation.rawScore = holeQuestion.gradingScale;
    evaluation.scaledScore = 1;
    const totalItemsInQuestion = holeQuestion.answers.filter(
      ({ isIntrusive }) => !isIntrusive,
    ).length;
    const totalItemsInAnswer = holeQuestionAnswer.answers.filter(
      (a) => a,
    ).length;

    if (totalItemsInQuestion > 0) {
      const nbCorrectAnswers = evaluation.results.reduce(
        (sum, { isCorrect }) => (isCorrect ? sum + 1 : sum),
        0,
      );
      const exactScoreRatio = nbCorrectAnswers / totalItemsInQuestion;
      evaluation.rawScore = exactScoreRatio * holeQuestion.gradingScale;
      evaluation.scaledScore = exactScoreRatio;
    }

    evaluation.isAnswerComplete = totalItemsInQuestion == totalItemsInAnswer;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, tags = {}) {
    const tagsToEvaluate = JSON.parse(JSON.stringify(tags));
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    let answersToEvaluate = [];
    itemToEvaluate.answers.forEach(({ id, isIntrusive }, index) => {
      const answer = tagsToEvaluate?.[ANSWER_KEY + index]?.[0];
      if (answer && !isIntrusive) {
        answersToEvaluate.push({
          ...answer,
          id,
        });
      } else {
        answersToEvaluate.push(null);
      }
    });
    const answer = new HoleQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      answers: answersToEvaluate,
    });
    const evaluation = this.evaluate(item, answer);
    return {
      answer,
      evaluation,
    };
  }

  generateTagsObject(question, evaluationState) {
    if (!(question instanceof HoleQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "HoleQuestionModel"',
      );
    }

    let tagsList = [];
    const tagsObject = {
      tagsList: [],
    };
    question.answers.forEach((answer, index) => {
      tagsList = tagsList.concat(answer);
      if (!answer.isIntrusive) {
        tagsObject[ANSWER_KEY + index] = [];
      }
    });

    // do not shuffle if activity is already answered
    if (evaluationState === activityItemStates.NOT_ANSWERED) {
      tagsList = shuffleArray(tagsList);
    }
    tagsObject.tagsList = tagsList;

    return tagsObject;
  }
}
